
.board-tabs {
    margin-bottom: 40px;
    ::v-deep(.v-slide-group__prev) {
        display: none;
    }
    .v-tab {
        background-color: #f5f5f5;
        &:first-of-type {
            border-top-left-radius: 999px;
            border-bottom-left-radius: 999px;
        }
        &:last-of-type {
            border-top-right-radius: 999px;
            border-bottom-right-radius: 999px;
        }
        &--active {
            background-color: var(--v-primary-base);
            color: #fff;
        }
    }
}
.library-report {
    padding: 40px 0;
    margin-bottom: 40px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        max-width: 1920px;
        height: 100%;
        background: rgb(100, 148, 67) url(/images/news/library.jpg) no-repeat center / cover;
    }
    &__inner {
        position: inherit;
        z-index: 1;
    }
}
@media (min-width: 768px) {
    .board-tabs {
        margin-bottom: 60px;
        ::v-deep(.v-tabs-bar),
        .v-tab {
            height: 60px;
        }
    }
    .library-report {
        padding: 86px 0;
        margin-bottom: 80px;
    }
}
